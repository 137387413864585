import { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { WEB_SERVICES_URL } from '../constants/Url';

const AuthContext = createContext(undefined);

export const AuthProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [mode, setMode] = useState();
  const [validSession, setValidSession] = useState(false);
  const [userdata, setUserData] = useState(false);
  const [data, setData] = useState({});
  
  useEffect(() => {
    // Hacer la solicitud HTTP a tu servicio REST
    const token = localStorage.getItem("id_token");
    fetch(WEB_SERVICES_URL+'/users/mode/'+token)
      .then(response => response.json())
      .then(data => setMode(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const verifyToken = async(token) => {
    let response = null;
    try {
      response = await axios.post(WEB_SERVICES_URL+'/verifyToken', { token });
      setLoggedIn(response.data.valid);
      setData(response.data);
      setValidSession(response.data.valid);
    } catch (error) {
        console.error('Error al verificar el token:', error);
    }
    // console.log(response);
    if(response != null) {
      
      // console.log("auth logged in: " + response.data.valid);
      // console.log("auth valid session: "+response.data.valid);
      if(!response.data.valid) {
        logout();
      }else{
        setSession({
          token: token,
          expiresIn: response.data.data.expiresIn
        });
        // console.log("auth token after login: "+localStorage.getItem('id_token'));
      }
    }else{
      logout()
    }
    
  };

   const setSession = (authResult) => {
        const expiresInInSeconds = authResult.expiresIn; // Duración de la sesión en segundos
        const currentDateTime = new Date();
        const expiresAt = currentDateTime.getTime() + expiresInInSeconds * 1000;
        
        
        localStorage.setItem('id_token', authResult.token);
        localStorage.setItem("expires_at", expiresAt);
        setLoggedIn(true);
    } 

    const logout = () => {
            localStorage.removeItem("id_token");
            localStorage.removeItem("expires_at");
            setLoggedIn(false);
    };

    const isLoggedIn = () => {
        return moment().isBefore(getExpiration());
    };

    const getExpiration = () =>  {
        const expiration = localStorage.getItem("expires_at");
        if(expiration) {
          const expiresAt = JSON.parse(expiration);
          return moment(expiresAt);
        }else{
          return null;
        }
    }

  return (
    <AuthContext.Provider value={{ loggedIn, isLoggedIn, verifyToken, logout, mode, setMode }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
