// Componente reutilizable para el input de tipo texto
export const TextInput = ({ name, label, value, onChange }) => (
    <div>
      <label htmlFor={name}>{label}:</label>
      <input type="text" name={name} id={name} value={value} onChange={onChange} />
    </div>
  );
  
  // Componente reutilizable para el input de tipo número
  export const NumberInput = ({ name, label, value, onChange }) => (
    <div>
      <label htmlFor={name}>{label}:</label>
      <input type="number" name={name} id={name} value={value} onChange={onChange} />
    </div>
  );
  
  // Componente reutilizable para el input de tipo radio
  export const RadioInput = ({ name, label, options, onChange }) => (
    <div>
      <label>{label}:</label>
      {options.map((option) => (
        <div key={option}>
          <input type="radio" name={name} value={option} onChange={onChange} /> {option}
        </div>
      ))}
    </div>
  );
  
  // Componente reutilizable para el input de tipo checkbox
  export const CheckboxInput = ({ name, label, value, onChange }) => (
    <div>
      <label>
        <input type="checkbox" name={name} checked={value} onChange={onChange} />
        {label}
      </label>
    </div>
  );
  
  export const DateInput = ({name, label, value, onChange}) => (
  <div>
    <label htmlFor="fechaHorario">{label}</label>
    <input type="datetime-local" name={name} onChange={onChange} value={value}/>
  </div>
  );
  