import { ArrowBackIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import React from 'react';

function BotonVolver(props) {
    const { setFormType } = props;

    const handleClick = () => {
        setFormType("");
    };

    return (
        <Button leftIcon={<ArrowBackIcon boxSize={12} />} onClick={handleClick}></Button>
    );
}

export default BotonVolver;
