import { Link } from 'react-router-dom';

export default function About (props) {
    const {setLastVisitedRoute} = props;
    setLastVisitedRoute('/about');
    return (    <div className="landing-page">

    {/* Sección de bienvenida */}
    <section className="welcome-section">
      <div className="container">
        <h1 className="text-primary">Servicios para tu Hogar o Empresa</h1>
        <p>Encuentra una amplia gama de servicios de limpieza, jardinería, plomería, carpintería y más.</p>
        <Link className="btn-custom btn-primary bg-primary" style={{ textDecoration: 'none' }} to="/login">¡Solicita tu Servicio Ahora!</Link>
      </div>
    </section>

    {/* Sección de servicios */}
    <section className="services-section">
      <div className="container">
        {/* Lista de servicios ofrecidos */}
        Llenar con contenido general sobre de qué trata esta página
      </div>
    </section>

    {/* Sección de testimonios */}
    <section className="testimonials-section">
      <div className="container">
        {/* Testimonios de clientes */}
      </div>
    </section>

  </div>)
}