import React, { useEffect, useState } from "react";
import axios from "axios";
import { Switch, Text, VStack } from "@chakra-ui/react";
import { useAuth } from '../../context/AuthContext';
import { WEB_SERVICES_URL } from '../../constants/Url';

const ChangeMode = () => {
    const { mode, setMode } = useAuth();

  const handleClickCambiarModo = (mode) => {
    const token = localStorage.getItem('id_token');

    axios.put(WEB_SERVICES_URL+`/users/changemode/update`, { mode: mode, token: token })
      .then(response => {
      })
      .catch(error => {
        console.error('Error al cambiar modo:', error);
      });
  };

  const handleSwitchChange = () => {
    setMode(mode === "cliente" ? "proveedor" : "cliente");
  };

  useEffect(() => {
    if (mode) {
      handleClickCambiarModo(mode);
    }
  }, [mode]);
  

  return (
    <VStack spacing={4} align="stretch">
      <Text fontSize="xl" fontWeight="bold">{mode === 'cliente'? "Cliente" : "Proveedor"}</Text>
      <Switch
        colorScheme="teal"
        size="lg"
        onChange={handleSwitchChange}
        isChecked={mode === "cliente"}
      />
    </VStack>
  );
};

export default ChangeMode;
