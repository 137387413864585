import React, {useState} from "react";
import axios from "axios";

const ClienteServicioEnProceso = ({
  id,
  tipoServicio,
  metrosCuadrados,
  habitaciones,
  banos,
  frecuencia,
  serviciosEspecificos,
  accesibilidad,
  equiposSuministros,
  fechaHorario,
  presupuestoCliente,
  serviciosAdicionales,
  usuarioAsignado
}) => {
  

  return (
    <div className="w3-third w3-container w3-margin-bottom card-servicios">
      <img
        src="https://www.w3schools.com//w3images/mountains.jpg"
        alt="Norway"
        style={{ width: "100%" }}
        className="w3-hover-opacity"
      />
      <div className="w3-container w3-white">
        
      <br/>
      <div className="footer-servicio">
        <p className="text-center">{usuarioAsignado === "" ? "Esperando un proveedor..." : "Solicitud Aceptada"}</p>
      </div>
      <br/>
        <p>
          <b>Tipo de Servicio: {tipoServicio}</b>
        </p>
        <p>Metros Cuadrados: {metrosCuadrados}</p>
        <p>Habitaciones: {habitaciones}</p>
        <p>Baños: {banos}</p>
        <p>Frecuencia: {frecuencia}</p>
        <p>Servicios Específicos:</p>
        {/* <ul>
          {serviciosEspecificos.map((servicio, index) => (
            <li key={index}>{servicio}</li>
          ))}
        </ul> */}
        <p>Accesibilidad: {accesibilidad ? "Sí" : "No"}</p>
        <p>Equipos y Suministros: {equiposSuministros ? "Sí" : "No"}</p>
        <p>Fecha y Horario Preferido: {fechaHorario}</p>
        <p>Presupuesto del Cliente: {presupuestoCliente}</p>
        <p>Servicios Adicionales: {serviciosAdicionales}</p>
      </div>
    </div>
  );
};

export default ClienteServicioEnProceso;
