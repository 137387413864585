import { Link } from 'react-router-dom';
import CardLandingPage from './CardLandingPage';
import heroImg from '../a1c6ae1e40a240b5e24c594284458344.jpeg';
import mainImg from '../2a72b9a873b2b3f8b312e4d4823a0d6b.jpeg';
import {ServiciosDisponibles} from './../constants/ServiciosDisponibles';


export default function LandingPage () {
    
    return (
      
      <>
      
      <section className="hero height-300">
        <picture className='w-100 radius-20-inf'>
          <img className='w-100 radius-20-inf' src={heroImg}/>
        </picture>
        <div className="hero-title bg-primary-w-opacity">
          <h1>SERVICIOS PARA TU HOGAR O EMPRESA</h1>
        </div>
        <div className="bg-secondary hero-p radius-15 centered-25">
          <p>Encuentra una amplia gama de servicios de limpieza, jardinería, plomería, carpintería y más.</p>
        </div>
      </section>

      <section className="main-article-section">
        <Link className="radius-15 btn-custom bg-secondary text-primary centered-25 text-center" style={{ textDecoration: 'none' }} to="/login">¡Solicitar Servicio!</Link>
        <article className="main-article">
          <picture>
            <img src={mainImg}/>
          </picture>
          <div>
            <h3 className='main-article-title radius-15 centered-75 text-center'>Información</h3>
            <p className='bg-primary text-secondary p-2 main-article-p'>
              En UnClickServicios, estamos comprometidos a hacer tu vida más fácil, conectándote con una amplia gama de servicios para satisfacer todas tus necesidades de limpieza, jardinería, plomería, carpintería y más. Ya sea que necesites renovar tu hogar o mantener tu negocio en óptimas condiciones, estamos aquí para ayudarte.
            </p>
          </div>
        </article>
      </section>

      <section className='landing-servicios'>
        <h2>Servicios Disponibles</h2>
        <div className="container-servicios">
        {ServiciosDisponibles.map((servicio) => {
          return <CardLandingPage key={servicio.value} src={servicio.src} title={servicio.title} />;
        })}

        </div>
      </section>
      
      </>
      
    )
}