import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import logoGoogle from '../../logo_google.png';
import { WEB_SERVICES_URL } from '../../constants/Url';

export default function Login(props) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { verifyToken, isLoggedIn, logout } = useAuth(); // Agrega la función isLoggedIn del contexto AuthContext
    const {setLastVisitedRoute} = props;
    setLastVisitedRoute('/');

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const token = params.get('token') ?? localStorage.getItem('id_token');
        
        if (!isLoggedIn()) {
            handleLoginWithToken(token);
        } else if (isLoggedIn()) {
            // Verifica si el usuario ya está logeado
            // Si el usuario ya está logeado, redirige a otra página, por ejemplo:
            window.location.href = '/'; // Cambia '/dashboard' por la ruta deseada
        }
    }, []); // Agrega isLoggedIn a las dependencias del useEffect

    const handleLoginWithToken = async (token) => {
        try {
            if(token === undefined) {
                console.log(token);
                logout();
                window.location.href = "/";
            }
            await verifyToken(token);
        } catch (error) {
            console.error('Error al iniciar sesión:', error);
        }
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            // Aquí podrías realizar alguna validación de los campos del formulario

            // Llamar a la función de verificación de token con los datos del formulario
            await verifyToken(email, password);
        } catch (error) {
            console.error('Error al iniciar sesión:', error);
        }
    };

    return (
        !isLoggedIn() &&
        <div className="container">
            <div className="login-box">
                <h1>Login</h1>
                <form onSubmit={handleLogin}>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            placeholder="correo@example.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            id="password"
                            placeholder="Contraseña"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="login-btn">
                        Login
                    </button>
                    <a className="link-google" href={WEB_SERVICES_URL+"/login"}>
                        <div className="google-btn">
                            <img className="logo-google" src={logoGoogle} alt="Login Google" />
                            Iniciar sesión con Google
                        </div>
                    </a>
                </form>
                <div className="signup-link">
                    <span>¿No tienes una cuenta?</span> <a href="#">Registrate</a>
                </div>
            </div>
        </div>
    );
}
