import React, {useState} from "react";
import axios from "axios";
import { WEB_SERVICES_URL } from '../../constants/Url';

const Servicio = ({
  id,
  tipoServicio,
  metrosCuadrados,
  habitaciones,
  banos,
  frecuencia,
  serviciosEspecificos,
  accesibilidad,
  equiposSuministros,
  fechaHorario,
  presupuestoCliente,
  serviciosAdicionales,
}) => {

  
  const [clicked, setClicked] = useState(false);

  const handleClickAceptarServicio = () => {
    const token = localStorage.getItem('id_token');

    axios.put(WEB_SERVICES_URL+`/limpieza/asignar/${id}`, { token: token })
      .then(response => {
        // Manejar la respuesta según sea necesario
        // console.log(response.data);
        setClicked(true);
      })
      .catch(error => {
        // Manejar errores de la solicitud
        console.error('Error al aceptar el servicio:', error);
      });
  };

  return (
    !clicked && <div className="w3-third w3-container w3-margin-bottom card-servicios">
      <img
        src="https://www.w3schools.com//w3images/mountains.jpg"
        alt="Norway"
        style={{ width: "100%" }}
        className="w3-hover-opacity"
      />
      <div className="w3-container w3-white">
        <p>
          <b>Tipo de Servicio: {tipoServicio}</b>
        </p>
        <p>Metros Cuadrados: {metrosCuadrados}</p>
        <p>Habitaciones: {habitaciones}</p>
        <p>Baños: {banos}</p>
        <p>Frecuencia: {frecuencia}</p>
        <p>Servicios Específicos:</p>
        {/* <ul>
          {serviciosEspecificos.map((servicio, index) => (
            <li key={index}>{servicio}</li>
          ))}
        </ul> */}
        <p>Accesibilidad: {accesibilidad ? "Sí" : "No"}</p>
        <p>Equipos y Suministros: {equiposSuministros ? "Sí" : "No"}</p>
        <p>Fecha y Horario Preferido: {fechaHorario}</p>
        <p>Presupuesto del Cliente: {presupuestoCliente}</p>
        <p>Servicios Adicionales: {serviciosAdicionales}</p>
      </div>

      <div className="footer-servicio">
        <button className="btn btn-custom" onClick={handleClickAceptarServicio}>Aceptar servicio</button>
      </div>
    </div>
  );
};

export default Servicio;
