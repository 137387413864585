import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
const MapComponent = () => {


  return (
    <>
{/*     
        <div class="form-group">
        <input type="text" id="pedido_latitud" name="pedido_latitud" value="" required />
        </div>
        <div class="form-group">
        <input type="text" id="pedido_longitud" name="pedido_longitud" value="" required />
        </div>
        <div id="map" style={{height: "400px"}}></div> */}
    </>
  );
};

export default MapComponent;
