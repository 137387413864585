import React from 'react';

const Header = (props) => {
  function openSidebar() {
      props.setIsSidebarOpen(true);
  }

  // function closeSidebar() {
  //     props.setIsSidebarOpen(false);
  // }
  return (
    <>
      <header id="portfolio">
            <span className="w3-button w3-hide-large w3-xxlarge w3-hover-text-grey" onClick={openSidebar}><i className="fa fa-bars"></i></span>
      </header>
    </>
  );
};

export default Header;