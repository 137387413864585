export default function CardLandingPage(props) {
    const { src, title, onClick } = props;
    
    const handleClick = () => {
        onClick();
    };

    return (
        <div className="card-landing" onClick={handleClick}>
            <picture className="picture-landing">
                <img src={src} alt={title}/>
            </picture>
            <p>{title}</p>
        </div>
    );
}
