import React, {useState} from "react";
import axios from "axios";
import { WEB_SERVICES_URL } from '../../../constants/Url';
import InputStars from '../../../forms/InputStars';

const ClienteServicioFinalizado = ({
  id,
  tipoServicio,
  metrosCuadrados,
  habitaciones,
  banos,
  frecuencia,
  serviciosEspecificos,
  accesibilidad,
  equiposSuministros,
  fechaHorario,
  presupuestoCliente,
  serviciosAdicionales,
  calificacionProveedor
}) => {
  const [userRating, setUserRating] = useState(0);

  const handleRatingChange = (rating) => {
    setUserRating(rating);
  };
  
  const [clicked, setClicked] = useState(false);
  const handleClickCalificarServicio = () => {
    const token = localStorage.getItem('id_token');

    axios.put(WEB_SERVICES_URL+`/limpieza/calificarproveedor/${id}`, { calificacionProveedor: userRating, token: token })
      .then(response => {
        // Manejar la respuesta según sea necesario
        // console.log(response.data);
        setClicked(true);
    })
      .catch(error => {
        // Manejar errores de la solicitud
        console.error('Error al aceptar el servicio:', error);
      });
  };

  return (
    <div className="w3-third w3-container w3-margin-bottom card-servicios">
      <img
        src="https://www.w3schools.com//w3images/mountains.jpg"
        alt="Norway"
        style={{ width: "100%" }}
        className="w3-hover-opacity"
      />
      <div className="w3-container w3-white">
        <p>
          <b>Tipo de Servicio: {tipoServicio}</b>
        </p>
        <p>Metros Cuadrados: {metrosCuadrados}</p>
        <p>Habitaciones: {habitaciones}</p>
        <p>Baños: {banos}</p>
        <p>Frecuencia: {frecuencia}</p>
        <p>Servicios Específicos:</p>
        {/* <ul>
          {serviciosEspecificos.map((servicio, index) => (
            <li key={index}>{servicio}</li>
          ))}
        </ul> */}
        <p>Accesibilidad: {accesibilidad ? "Sí" : "No"}</p>
        <p>Equipos y Suministros: {equiposSuministros ? "Sí" : "No"}</p>
        <p>Fecha y Horario Preferido: {fechaHorario}</p>
        <p>Presupuesto del Cliente: {presupuestoCliente}</p>
        <p>Servicios Adicionales: {serviciosAdicionales}</p>
      </div>

      {calificacionProveedor === "" && clicked === false  && <div className="footer-servicio">
        <InputStars onChange={handleRatingChange} />
        {/* <p>Tu calificación: {userRating}</p> */}
        <button className="btn-custom btn-calificar" onClick={handleClickCalificarServicio}>Calificar</button>
      </div>}
    </div>
  );
};

export default ClienteServicioFinalizado;
