import { StarIcon } from '@chakra-ui/icons';
import { Box, IconButton } from '@chakra-ui/react';
import { useState } from 'react';

const InputStars = ({ onChange }) => {
  const [rating, setRating] = useState(0);
  const totalStars = 5;

  const handleClick = (index) => {
    setRating(index + 1);
    onChange(index + 1);
  };

  return (
    <Box className="stars-container">
      {[...Array(totalStars)].map((_, index) => (
        <IconButton
          key={index}
          icon={<StarIcon />}
        //   backgroundColor={"#000"}
          color={index < rating ? 'yellow.500' : 'gray.300'}
          size="md"
          onClick={() => handleClick(index)}
          aria-label={`Star ${index + 1}`}
        />
      ))}
    </Box>
  );
};

export default InputStars;
