import limpiezaImg from '../2a72b9a873b2b3f8b312e4d4823a0d6b.jpeg';
import jardineriaImg from '../218352bc7ec79684fdc985c71097b804.jpeg';
import autolavadoImg from '../24a005cd6a901f180122be3e93fe6f0f.jpeg';
import camarasImg from '../581477aa2bbc787b3fb38b5a3f88428a.jpeg';
import carpinteriaImg from '../88e333bf79d6eb9d630a348ffe392e24.jpeg';
import melaminaImg from '../8b2e71272d0be634dee40b49babcc201.jpeg';
import plomeriaImg from '../90d643502ea32bd74afae41902f32572.jpeg';

export const ServiciosDisponibles = [
    {
      src: limpiezaImg,
      title: "LIMPIEZA",
      name: "limpieza"
    },
    {
      src: jardineriaImg,
      title: "JARDINERÍA",
      name: "Jardineria"
    },
    {
      src: autolavadoImg,
      title: "AUTOLAVADO",
      name: "lavadoAutoDomicilio"
    },
    {
      src: camarasImg,
      title: "CÁMARAS",
      name: "instalacionCamaras"
    },
    {
      src: carpinteriaImg,
      title: "CARPINTERÍA",
      name: "Carpinteria"
    },
    {
      src: melaminaImg,
      title: "MELAMINA",
      name: "armadoMueblesMelamina"
    },
    {
      src: plomeriaImg,
      title: "PLOMERÍA",
      name: "Plomeria"
    },
  ];