import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import logoGoogle from '../../logo_google.png';

export default function Login(props) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { verifyToken, isLoggedIn, logout } = useAuth(); // Agrega la función isLoggedIn del contexto AuthContext
    const {setLastVisitedRoute} = props;
    setLastVisitedRoute('/logout');

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const token = params.get('token') ?? localStorage.getItem('id_token');
        
        if (isLoggedIn()) {
            logout();
        }
    }, []); 
    

    return (
        !isLoggedIn() && <h3>Logged out</h3>
    );
}
